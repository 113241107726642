import React from "react";
import { Link, Section, Container } from "@components";
import { PageLayout } from "../../components/site/layout/page-layout";

import { StaticImage } from "gatsby-plugin-image";
import {
  mobileFinanceExperienceList,
  mobileFinanceGamingList,
} from "../../content/market/data";
import { ResourceProvider } from "../../components/site/resources/resource-context";

const MobileFinance = ({ location }) => {
  const renderGamingItem = ({ title, text, key, icon }) => {
    return (
      <div className="flex" key={key}>
        <div className="w-fit h-fit rounded-[7px] p-[8px] bg-white">{icon}</div>
        <div className="w-full ml-[30px]">
          <h5 className="my-0 text-[20px] leading-[27px] font-semibold text-[#1B3D57]">
            {title}
          </h5>
          <div
            className="mt-[27px] text-[17px] text-[#262727]"
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </div>
      </div>
    );
  };

  const renderExperienceItem = (item, index) => {
    return (
      <div key={index} className="text-center">
        {item.icon}
        <h5 className="max-w-16 mt-[10px] mb-0 text-[20px] leading-[27px] font-semibold text-[#1B3D57]">
          {item.title}
        </h5>
        <div className="text-left mt-[10px] text-[17px] text-[#262727]">
          {item.text}
        </div>
      </div>
    );
  };

  return (
    <PageLayout pathname={location.pathname}>
      {/* Title Text start */}
      <div className="bg-[#122331] w-full">
        <Section>
          <Container className="py-[100px] md:py-[30px]">
            <div className="md:flex justify-between items-center">
              <div className="w-full md:w-[450px]">
                <h1 className="w-fit m-auto text-[40px] leading-[50px] md:ml-0 md:text-[53px] md:leading-[70px] font-semibold text-[#ffffff] border-b-[6px] border-[#B21C7A] pb-[18px]">
                  <span style={{ color: "#86b146" }}>Securing </span> Financial
                  Services
                  <br /><span style={{ color: "#86b146" }}> Mobile Apps</span>
                </h1>
              </div>
              <div className="w-full md:w-1/2 mt-[58px] md:mt-0">
              <StaticImage
                  src={`../../components/site/markets/images/industries.png`}
                  className="w-full md:max-w-[593px] h-auto"
                  alt="Mobile App and API Protection | Approov"
                  objectFit="contain"
                />
              </div>
            </div>
          </Container>
        </Section>
      </div>
      {/* Title Text end */}

      <Section className="pt-[70px] pb-[90px]">
        <Container>
          <div className="w-full max-w-[990px] text-center mx-auto">
            <h2 className="max-w-3xl m-auto text-[25px] lg:text-[31px] font-semibold text-[#1B3D57] my-0">
              Approov Provides Easy, Fast, Effective Protection for all
              Financial Services Applications
            </h2>
            <div className="text-left text-[17px] leading-[29px] text-black mt-[20px]">
              Approov provides fintech app developers and bank security teams an
              easy way to stay ahead of fake apps, data theft, fraud, and API
              attacks. With Approov in place, you protect your clients assets
              and private data, and protect your business reputation. From
              mobile banking to investment and crypto apps,{" "}
              <Link href="/mobile-app-security/rasp/">
                Approov mobile runtime application self-protection (RASP)
              </Link>{" "}
              protects your apps with minimal impact to development and no
              impact on customer experience.
            </div>
            <Link
              href="https://approov.io/info/schedule-a-demo"
              className="!no-underline inline-block !text-white bg-[#78A65A] mt-[40px] px-[38px] py-[16px] rounded-[7px] font-medium text-[16px] leading-[28px] text-center transition hover:bg-[#1b7609] mx-auto"
            >
              Request a Demo
            </Link>
          </div>
        </Container>
      </Section>
      <Section className="pt-[70px] pb-[90px] bg-[#f2f5f9]">
        <Container>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-[82px] gap-y-[40px]">
            {mobileFinanceGamingList.map((item, index) =>
              renderGamingItem({
                title: item.title,
                text: item.text,
                icon: item.icon,
                key: index,
              })
            )}
          </div>
        </Container>
      </Section>
      <Section className="pt-[90px] pb-[90px]">
        <Container>
          <div className="pb-[90px]">
            <h2 className="max-w-3xl text-center m-auto text-[25px] lg:text-[31px] font-semibold text-[#1b3d57] my-0">
              Financial Services in the Palm of Your Hand
            </h2>
          </div>
          <div className="md:flex justify-between items-center">
            <StaticImage
              src={`../../components/site/customers/images/Papara_image.png`}
              className="w-full md:max-w-[550px] md:max-h-[515px] h-auto rounded-[10px]"
              alt="Background image of man standing in front of Sixt rental car; close up of a smartphone showing Sixt app in foreground."
              objectFit="cover"
            />
            <div className="w-full md:ml-[80px] mt-[40px] md:mt-0">
              <div class="flex-1 md:pr-8">
                  <blockquote>
                      <p className="mb-4 text-[18.7px]">
                      Having completed the integration and test in less than 30 days, we deployed the Approov protection and instantly saw the costs due to the fraudsters drop by 90%.
                      </p>
                  </blockquote>
                  <p className="text-[18.7px] w-[94%] mt-[9px] leading-[26px] pl-[15px] pr-[15px] md:p-[0px] text-right italic not-prose ">
                      - Emre Kenci, CTO, Papara
                  </p>
              </div>
              <div class="flex justify-center">
                <Link
                  href="/customer/papara/"
                  className="!no-underline !text-white inline-block bg-[#78A65A] mt-[25px] px-[38px] py-[16px] rounded-[7px] font-medium text-[16px] leading-[28px] text-center md:w-fit transition hover:bg-[#1b7609]"
                >
                  Papara Success Story
                </Link>
              </div>
            </div>
          </div>
        </Container>
      </Section>
      <Section className="pt-[90px] pb-[90px]">
        <Container>
          <div className="md:flex justify-between items-center">
            <StaticImage
              src={`../../components/site/customers/images/Metal_Pay.png`}
              className="w-full md:max-w-[550px] md:max-h-[515px] h-auto rounded-[10px]"
              alt="Background image of man standing in front of Sixt rental car; close up of a smartphone showing Sixt app in foreground."
              objectFit="cover"
            />
            <div className="w-full md:ml-[80px] mt-[40px] md:mt-0">
              <div class="flex-1 md:pr-8">
                  <blockquote>
                      <p class="mb-4 text-[18.7px]">
                        Looking at the traffic we’ve been able to counter with Approov and the financial fraud that usually comes with it I’d be willing to guesstimate at least a 10x return on our investment with Approov.
                      </p>
                  </blockquote>
                  <p className="text-[18.7px] w-[94%] mt-[9px] leading-[26px] pl-[15px] pr-[15px] md:p-[0px] text-right italic not-prose ">
                    – Glenn Marien <br/> CTO and Co-founder <br/> Metallicus
                  </p>
              </div>
              <div class="flex justify-center">
              <Link
                href="/customer/metalpay/"
                className="!no-underline !text-white inline-block bg-[#78A65A] mt-[25px] px-[38px] py-[16px] rounded-[7px] font-medium text-[16px] leading-[28px] text-center md:w-fit transition hover:bg-[#1b7609]"
              >
                Metal Pay Success Story
              </Link>
              </div>
            </div>
          </div>
        </Container>
      </Section>
      <Section className="pt-[70px] pb-[90px] bg-[#f2f5f9]">
        <Container>
          <div className="w-full max-w-[1024px] text-center mx-auto">
            <h2 className="max-w-2xl m-auto text-[25px] lg:text-[31px] font-semibold text-[#1B3D57] my-0">
              Establish Customer Trust with Approov Mobile Protection
            </h2>
          </div>
          <div className="mt-[50px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-[23px] gap-y-[40px]">
            {mobileFinanceExperienceList.map((item, index) =>
              renderExperienceItem(item, index)
            )}
          </div>
        </Container>
      </Section>
      <Section className="pt-[70px] pb-[90px] bg-[#092A40]">
        <Container>
          <div className="w-full max-w-[1024px] text-center mx-auto">
            <div className="text-[#9ACA7A] text-[22px] font-semibold">
              Developer-First Design
            </div>
            <h2 className="text-[31px] leading-[43px] font-semibold text-white mb-0 mt-[20px]">
              One Sprint Deployment to Fully Secure your Finance App
            </h2>
          </div>
          <div className="md:flex mt-[40px]">
            <div className="text-[17px] text-white">
              Our Integrations are simple and can be completed in one single
              sprint!  We are here with you every step of the way and support
              you from implementation to deployment and beyond.
            </div>
            <div className="min-w-[240px] mt-[40px] md:mt-0 md:ml-[90px]">
              <div class="flex justify-center">
                <Link
                  href="/signup/"
                  className="!no-underline !text-white inline-block bg-[#78A65A] px-[38px] py-[16px] rounded-[7px] font-medium text-[16px] leading-[28px] text-center md:w-fit transition hover:bg-[#1b7609]"
                >
                  Get Your API Key
                </Link>
              </div>
            </div>
          </div>
          <div className="mt-[60px] grid grid-cols-1 md:grid-cols-2 gap-x-[93px] gap-y-[50px]">
            <div className="flex">
              <div className="w-fit h-fit">
                <StaticImage
                  src={`../../components/site/markets/images/gaming-integration-1.png`}
                  className="w-[56px] h-[52.5px]"
                  alt="Gaming Experience Item"
                  objectFit="contain"
                />
              </div>
              <div className="w-full ml-[30px]">
                <div className="text-[20px] leading-[27px] font-bold text-white">
                  Fully Compatible CLI
                </div>
                <div className="text-[17px] leading-[29px] text-white">
                  Works on Windows, Mac and Linux
                </div>
              </div>
            </div>
            <div className="flex">
              <div className="w-fit h-fit">
                <StaticImage
                  src={`../../components/site/markets/images/gaming-integration-2.png`}
                  className="w-[54px] h-[50.55px]"
                  alt="Gaming Experience Item"
                  objectFit="contain"
                />
              </div>
              <div className="w-full ml-[30px]">
                <div className="text-[20px] leading-[27px] font-bold text-white">
                  One Sprint Deployment
                </div>
                <div className="text-[17px] leading-[29px] text-white">
                  Quick starts for all major platforms making integration easy.
                </div>
              </div>
            </div>
            <div className="flex">
              <div className="w-fit h-fit">
                <StaticImage
                  src={`../../components/site/markets/images/gaming-integration-3.png`}
                  className="w-[56px] h-[52.5px]"
                  alt="Gaming Experience Item"
                  objectFit="contain"
                />
              </div>
              <div className="w-full ml-[30px]">
                <div className="text-[20px] leading-[27px] font-bold text-white">
                  Reliable SLA and Support
                </div>
                <div className="text-[17px] leading-[29px] text-white">
                  Approov prides itself on customer support, we partner with you
                  to address your security needs.
                </div>
              </div>
            </div>
            <div className="flex">
              <div className="w-fit h-fit">
                <StaticImage
                  src={`../../components/site/markets/images/gaming-integration-4.png`}
                  className="w-[54px] h-[50.55px]"
                  alt="Gaming Experience Item"
                  objectFit="contain"
                />
              </div>
              <div className="w-full ml-[30px]">
                <div className="text-[20px] leading-[27px] font-bold text-white">
                  Intelligent Interfaces
                </div>
                <div className="text-[17px] leading-[29px] text-white">
                  The approov SDK attaches directly to your networking stack.
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Section>
      <ResourceProvider>
        <Section className="pt-[84px] bg-[#f2f5f9] text-center">
          <Container>
            <h2 className="max-w-3xl m-auto text-[25px] lg:text-[31px] font-semibold text-[#1B3D57] my-0">
              More Resources about Mobile Finance
            </h2>
          </Container>
        </Section>
      </ResourceProvider>
    </PageLayout>
  );
};

export default MobileFinance;
